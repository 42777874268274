.menu {
  &.inactive {
    .menu-item {
      animation: fade-out 0.2s ease forwards;

      &:nth-child(2) {
        animation: fade-out 0.2s ease 0.4s forwards;
      }

      &:nth-child(3) {
        animation: fade-out 0.2s ease 0.2s forwards;
      }
    }
  }

  .menu-item.active {
    span:first-child {
      animation: fade-in-out 0.5s linear 0.2s forwards;
    }

    &::before,
    &::after {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &.selecting {
    .menu-item.active {
      span:first-child {
        animation: blink 0.5s linear !important;
      }
    }
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-content {
      position: relative;
    }

    img {
      display: inline-block;
      margin-left: 5px;
      height: 28px;
      min-width: 54px;
    }

    span {
      position: relative;
      z-index: 0;
      font-size: 4.5rem;
    }

    span:first-child {
      position: absolute;
      z-index: 1;
      left: calc(50%);
      top: calc(50%);
      transform: translateX(-50%) translateY(-50%);
      color: #e71d93;
      opacity: 0;
    }

    &::before,
    &::after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      margin: 0 5px;
      opacity: 0;
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    &::before {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid rgb(245, 245, 245);
      filter: drop-shadow(-3px 0 0 #e71d93);
      transform: translateX(-50%);
    }

    &::after {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid rgb(245, 245, 245);
      filter: drop-shadow(3px 0 0 #e71d93);
      transform: translateX(50%);
    }
  }
}
