@import '../../style/utilities/variables';
@import '../../style/utilities/mixins';

.sign-in {
  position: absolute;
  z-index: 15;
  bottom: 0;
  left: 0;
  padding: 0.1 * $screenHeight 0.1 * $screenWidth;
  background-color: #000000;
  width: $screenWidth;
  min-height: $screenHeight + 40px;
  transform-origin: bottom left;

  p, span {
    font-size: 3.5rem;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;

    span {
      text-transform: uppercase;
      line-height: 30px;
    }
  }

  &__section {
    margin-bottom: 40px;
  }

  form label {
    position: relative;
  }

  &__logo,
  form {
    width: 280px;
    margin: 0 auto;
  }

  &__label,
  &__nickname {
    margin: 0;
    color: white;
    text-transform: uppercase;
    font-family: 'VT323', monospace;
  }

  &__nickname {
    position: absolute;
    opacity: 0;
  }

  &__logo {
    position: relative;
    max-width: 100%;
    margin: 0 auto 5vh auto;

    .dash-array {
      left: 50%;
      display: block;
      width: 100%;
    }

    &-text {
      width: 100%;
      margin: 10px 0;
    }
  }

  .moving-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2b2b2b;
    animation: slid-out 7s linear 4s both;
  }

  .white-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: #fff;
    opacity: 0.15;
  }
}

@keyframes slid-out {
  100% {
    transform: translateY(100%);
  }
}
