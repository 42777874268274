@import url(https://fonts.googleapis.com/css?family=VT323);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

polyline#Path-25 {
  cursor: none; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

::-webkit-scrollbar {
  width: 38px;
  height: 48px; }

::-webkit-scrollbar-track {
  background-color: #CCC3DF; }

::-webkit-scrollbar-thumb {
  background-color: #472888; }

::-webkit-scrollbar-button {
  display: block;
  width: 40px;
  height: 60px;
  background-color: #311D5C; }
  ::-webkit-scrollbar-button:vertical:decrement {
    height: 70px;
    background-color: #311d5c;
    background-image: url(/static/media/scrollTop.5e29d506.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain; }
  ::-webkit-scrollbar-button:vertical:increment {
    height: 90px;
    background-color: #311d5c;
    background-image: url(/static/media/scroll.e1103f2d.svg);
    background-repeat: no-repeat;
    background-size: contain; }
  ::-webkit-scrollbar-button:vertical:increment:start {
    display: none; }
  ::-webkit-scrollbar-button:vertical:decrement:end {
    display: none; }

.game-pad {
  position: relative;
  height: 100%;
  width: 100%;
  cursor: default; }
  .game-pad__images, .game-pad__buttons {
    left: 50%;
    top: 50%;
    width: 100vmax;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }
  .game-pad__images {
    position: relative;
    display: flex;
    align-items: stretch;
    overflow: hidden; }
  .game-pad__buttons {
    position: absolute; }

.padBgc {
  z-index: 0;
  width: 100vmax; }

.padBgc,
.middle {
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%); }

.middle {
  z-index: 2; }

.middle,
.padBgc,
.right-side,
.left-side {
  position: absolute;
  height: 100%;
  -webkit-transform-origin: center;
          transform-origin: center; }

.left-side {
  z-index: 1;
  top: 0;
  left: 0; }

.right-side {
  z-index: 1;
  bottom: 0;
  right: 0; }

.pad-arrow-back.arrow-back {
  top: 0;
  left: 0;
  width: 30vmin;
  height: 20vmin;
  padding: 0;
  background-image: url(/static/media/back-arrwo.7fc7c02d.svg);
  -webkit-animation: fadeIn 0.5s ease 4s forwards;
          animation: fadeIn 0.5s ease 4s forwards;
  background-size: contain;
  background-position: top left; }
  .pad-arrow-back.arrow-back:hover {
    -webkit-transform: none;
            transform: none; }

@media screen and (orientation: portrait) {
  .game-pad__buttons, .game-pad__images {
    left: 50%;
    top: 0;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: rotate(90deg) translateY(-50%);
            transform: rotate(90deg) translateY(-50%); } }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-light-in {
  from {
    opacity: 0.8; }
  to {
    opacity: 1; } }

@keyframes fade-light-in {
  from {
    opacity: 0.8; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fade-in-out {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-in-out {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fade-inactive-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-inactive-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes btnTransform {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(0.5vh);
            transform: translateY(0.5vh); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes btnTransform {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(0.5vh);
            transform: translateY(0.5vh); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes moveLeft {
  0% {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%); }
  80% {
    -webkit-transform: translateX(300%);
            transform: translateX(300%); }
  100% {
    -webkit-transform: translateX(300%);
            transform: translateX(300%); } }

@keyframes moveLeft {
  0% {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%); }
  80% {
    -webkit-transform: translateX(300%);
            transform: translateX(300%); }
  100% {
    -webkit-transform: translateX(300%);
            transform: translateX(300%); } }

@-webkit-keyframes moveLeftPrevent {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  20% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  40% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  60% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  80% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  100% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); } }

@keyframes moveLeftPrevent {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  20% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  40% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  60% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  80% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  100% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); } }

@-webkit-keyframes pulse {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  20% {
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  60% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  80% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); } }

@keyframes pulse {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  20% {
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  60% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  80% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); } }

@-webkit-keyframes blink {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  50% {
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes blink {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  50% {
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes show-box-shadow {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(7px, 7px);
            transform: translate(7px, 7px); } }

@keyframes show-box-shadow {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(7px, 7px);
            transform: translate(7px, 7px); } }

@-webkit-keyframes fade-in-from-bottom {
  0% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-from-bottom {
  0% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fade-in-from-top {
  0% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-from-top {
  0% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fade-out-from-top {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out-from-top {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fade-out-from-bottom {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out-from-bottom {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeAndScaleSparks {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2); } }

@keyframes fadeAndScaleSparks {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2); } }

* {
  box-sizing: border-box;
  font-family: "VT323", monospace; }

@font-face {
  font-family: 'RepetitionScrolling';
  font-weight: normal;
  src: url(/static/media/RepetitionScrolling.092906ac.ttf); }

:root {
  font-size: 10px; }

* {
  font-size: 3rem; }

html, body {
  user-zoom: fixed; }

body {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #ffffff;
  touch-action: manipulation; }
  body, body #root {
    overflow: hidden;
    width: 100vw;
    height: 100vh; }

.container {
  background-color: #190736; }

html {
  font-size: 62.5%;
  overflow: hidden; }

.hide-overflow {
  overflow: hidden; }

.GameContainer {
  position: relative;
  z-index: 1;
  top: 12%;
  height: 70%;
  max-width: 640px;
  margin: 0 auto;
  overflow: hidden;
  -webkit-transform-origin: center;
          transform-origin: center;
  background-color: #000;
  color: white; }

.hide {
  -webkit-animation: fade-out 0.5s ease forwards;
          animation: fade-out 0.5s ease forwards; }

button {
  background: transparent;
  outline: 0; }

.text--error {
  color: red;
  font-size: 16px; }

.text--pre {
  white-space: pre; }

.text--stretch {
  text-align: justify; }

.btn {
  background: transparent;
  border: 0;
  outline: 0; }
  .btn.clicked {
    -webkit-animation: btn-up-down 0.2s ease;
            animation: btn-up-down 0.2s ease; }
  .btn svg {
    max-width: 100%; }
  .btn--transform {
    -webkit-animation: btnTransform 0.3s ease;
            animation: btnTransform 0.3s ease; }

.carousel-slider {
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 20px;
  -webkit-transition: "transform 400ms ease";
  transition: "transform 400ms ease"; }

.d-none {
  display: none; }

@-webkit-keyframes btn-up-down {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes btn-up-down {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

div.logo {
  left: 120px; }

.logo {
  width: 100%; }

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 365.4px;
  left: 100px;
  width: 200px; }

.menu button {
  text-transform: uppercase;
  color: #f0f0f0;
  background: none;
  border: none;
  padding: 0.5rem;
  margin: 10px 0; }

.ground {
  position: absolute;
  bottom: 0;
  width: 4800px;
  left: -10%;
  height: 108px;
  background-image: url(/static/media/podloga2.67cc093c.svg);
  background-repeat: repeat-x; }

.shrub0-img {
  position: absolute;
  bottom: 108px;
  height: 107px;
  -webkit-transform: translateX(-60%);
          transform: translateX(-60%); }

.shrub1 {
  position: absolute;
  bottom: 0;
  height: 140px; }
  .shrub1.parallax.parallax-2 {
    z-index: 1; }
  .shrub1 img {
    -webkit-transform: translateX(30%);
            transform: translateX(30%); }

.shrub2a {
  position: absolute;
  bottom: -15px;
  left: 2025px;
  height: 170px; }

.shrub4 {
  position: absolute;
  bottom: 108px;
  left: 600px;
  height: 107px; }

.ceiling1 {
  position: absolute;
  z-index: 3;
  top: 0;
  height: 112px;
  -webkit-transform: translateX(-25%);
          transform: translateX(-25%); }

.lamppost {
  position: absolute;
  bottom: 0;
  left: 1200px;
  width: 154px !important; }

.shrub5 {
  position: absolute;
  bottom: -5px;
  left: 1440px; }

.players-matching {
  position: absolute;
  top: 20vh;
  left: 500vw; }

.shrub6 {
  position: absolute;
  bottom: -2vh;
  left: 2800px; }

.shrub6.shrub7 {
  left: 5238px; }
  .shrub6.shrub7,
  .shrub6.shrub7 .big-shurb {
    bottom: auto;
    top: 0; }
  .shrub6.shrub7 .big-shurb {
    -webkit-transform: none;
            transform: none; }

.big-shurb {
  position: absolute;
  height: 812px;
  bottom: 0;
  -webkit-transform: rotateY(-180deg) rotateZ(-90deg);
          transform: rotateY(-180deg) rotateZ(-90deg); }

.ceiling2 {
  position: absolute;
  left: 2400px;
  height: 40vh; }

.blackout {
  background-color: black;
  position: absolute;
  top: 0;
  left: 3600px;
  width: 400px !important;
  height: 100%; }
  .blackout-2 {
    left: 4980px;
    width: 100% !important; }
    .blackout-2 .user-matching {
      margin: 0 85px; }

.lamppost2 {
  position: absolute;
  bottom: 0;
  left: 2320px;
  width: 60px; }

.black4 {
  position: absolute;
  left: 3840px;
  width: 1400px !important;
  height: 50%; }
  .black4--top {
    top: 100px; }
    .black4--top::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      background: black; }
  .black4--bottom {
    left: 3880px;
    bottom: -20px;
    width: 1500px !important;
    height: 60%; }
    .black4--bottom img {
      min-width: 100%;
      height: 100%; }

.game-pad-parallax {
  position: absolute;
  bottom: 0;
  left: 4270px;
  z-index: 100; }

.text-rect {
  width: 140px;
  height: 43px; }
  .text-rect svg {
    width: 100%;
    z-index: 0; }
  .text-rect span {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    display: block;
    padding: 0 9px 0 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-transform: uppercase;
    text-align: center;
    line-height: 43px; }

.game-over__scores {
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-100%) translateY(-50%);
          transform: translateX(-100%) translateY(-50%);
  font-size: 5rem; }
  .game-over__scores span {
    font-family: "RepetitionScrolling";
    color: #f12977;
    background-image: url(/static/media/score-bcg.76a213ac.svg);
    background-size: 6.33333px 6.6px; }

.game-over__message--bottom .enemy-response__message.enemy-response__message {
  margin-top: 12%;
  padding: 0; }

.game-over .user-matching__user-avatar {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.game-over__message {
  top: -25%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-transform: capitalize;
  opacity: 0;
  -webkit-animation: fade-in 0.5s ease 5s forwards;
          animation: fade-in 0.5s ease 5s forwards; }
  .game-over__message--bottom {
    top: auto;
    bottom: -25%;
    margin-top: 5%; }
    .game-over__message--bottom img {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.game-over .enemy-response__message {
  padding: 0;
  width: 100%;
  margin-top: 5%; }

.game-over__msg {
  position: absolute;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 4.5rem;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%); }
  .game-over__msg--top {
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-200%) translateX(-50%);
            transform: translateY(-200%) translateX(-50%); }
  .game-over__msg--bottom {
    position: absolute;
    top: auto;
    bottom: 0;
    -webkit-transform: translateY(300%) translateX(-50%);
            transform: translateY(300%) translateX(-50%); }
  .game-over__msg--bottom, .game-over__msg--top {
    font-size: 3rem; }
  .game-over__msg--clock {
    height: 40px; }

.game-over__button {
  position: absolute;
  z-index: 100;
  bottom: 5%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .game-over__button .btn {
    position: relative; }

.game-over__message {
  position: absolute; }
  .game-over__message p {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }

.game-over .game-over__scores .game-score {
  padding: 5px; }
  .game-over .game-over__scores .game-score, .game-over .game-over__scores .game-score::before {
    background-color: transparent; }

.game-over .game-over__scores .game-scores {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform: none;
          transform: none; }

.game-over .user-matching__user--top .game-over__scores {
  left: 50%;
  padding-right: 35px;
  -webkit-transform: translateX(-100%) translateY(-50%);
          transform: translateX(-100%) translateY(-50%); }

.game-over .user-matching__user--bottom .game-over__scores {
  left: 50%;
  padding-left: 35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.game-over .game-over .user-matching__user--bottom span {
  color: #7257b4; }

.arrow-back {
  position: fixed;
  z-index: 100;
  top: 30px;
  left: 22px;
  width: 35px;
  height: 25px;
  box-sizing: content-box;
  border: 0;
  outline: 0;
  opacity: 0;
  -webkit-animation: fadeIn 0.5s ease 4s 1 forwards;
          animation: fadeIn 0.5s ease 4s 1 forwards;
  background-image: url(/static/media/baack.926ed3c7.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease; }
  .arrow-back:hover {
    -webkit-transform: scale(1.25);
            transform: scale(1.25); }
  .arrow-back img {
    width: 100%; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.parallax.parallax-0 {
  z-index: 0; }

.parallax.parallax-1 {
  z-index: 1; }

.parallax.parallax-2 {
  z-index: 2; }

.parallax.parallax-3 {
  z-index: 3; }

.parallax-full {
  position: absolute;
  height: 100%;
  width: 6462px; }

.carousel {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  padding: 105px 0; }
  .carousel-container {
    position: relative;
    height: 100%;
    width: 100%; }

.slider .carousel.carousel--used .player-box, .slider .carousel.carousel--used .carousel-item--active, .slider .carousel.carousel--used .pad-name, .slider .carousel.carousel--used .pad-loader {
  opacity: 1; }

.slider {
  z-index: 5;
  margin: 36px 0 0 45px; }
  .slider .building {
    height: 675px; }
  .slider-building {
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 1315px; }
    .slider-building.parallax.parallax-1 {
      z-index: 2; }
  .slider__controls {
    position: absolute;
    z-index: 10;
    top: 0;
    width: 100%;
    height: 100%; }
  .slider__content {
    position: relative;
    z-index: 3;
    height: 617.12px;
    width: 340px;
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .slider-visible .select-box-invert {
    -webkit-transform: translate(7px, 7px);
            transform: translate(7px, 7px); }
  .slider .player-box {
    position: absolute;
    top: 50%;
    z-index: 3;
    height: auto;
    width: 130px;
    -webkit-transform: translateY(-50%) translateX(150%);
            transform: translateY(-50%) translateX(150%); }
  .slider .slider-btn {
    position: absolute;
    z-index: 10;
    bottom: 50px;
    left: 70px;
    width: 200px;
    height: 46px;
    text-align: center;
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease; }
    .slider .slider-btn:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
    .slider .slider-btn span {
      opacity: 0; }
    .slider .slider-btn .btn--rect {
      position: relative; }
    .slider .slider-btn svg {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transition: -webkit-transform 0.7s ease-in;
      transition: -webkit-transform 0.7s ease-in;
      transition: transform 0.7s ease-in;
      transition: transform 0.7s ease-in, -webkit-transform 0.7s ease-in; }
  .slider .btn-arrow {
    z-index: 10;
    text-align: center;
    height: 60px;
    width: 200px;
    border: none;
    opacity: 1;
    -webkit-transition: -webkit-transform 0.2s ease opacity 1s ease;
    transition: -webkit-transform 0.2s ease opacity 1s ease;
    transition: transform 0.2s ease opacity 1s ease;
    transition: transform 0.2s ease opacity 1s ease, -webkit-transform 0.2s ease opacity 1s ease; }
    .slider .btn-arrow__wrapper {
      position: absolute;
      z-index: 2;
      top: 0;
      display: flex;
      left: 50%;
      align-items: center;
      height: 100px;
      -webkit-transform: translateX(-50%) translateY(-100%);
              transform: translateX(-50%) translateY(-100%); }
      .slider .btn-arrow__wrapper--bottom {
        top: auto;
        bottom: 0;
        -webkit-transform: translateX(-50%) translateY(100%);
                transform: translateX(-50%) translateY(100%); }
    .slider .btn-arrow::before {
      content: '';
      display: inline-block;
      height: 60px;
      width: 35%;
      text-align: center;
      background: url(/static/media/arrow-up.c599f602.svg) no-repeat;
      background-size: 100% 90%;
      background-position: center; }
    .slider .btn-arrow:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
    .slider .btn-arrow:disabled::before {
      opacity: 0.5; }
    .slider .btn-arrow--up {
      top: 25px; }
    .slider .btn-arrow--down {
      bottom: 25px; }
      .slider .btn-arrow--down::before {
        background-image: url(/static/media/down-arrow.65ca49ca.svg); }
  .slider .btn--arrow-down {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .slider .carousel__slot--visible {
    -webkit-transition: opacity 1s ease 0.75s;
    transition: opacity 1s ease 0.75s; }
  .slider .carousel__slot--visible + .carousel__slot--visible {
    -webkit-transition: opacity 1s ease 1s;
    transition: opacity 1s ease 1s; }
  .slider .carousel__slot--visible + .carousel__slot--visible + .carousel__slot--visible {
    -webkit-transition: opacity 1s ease 1.25s;
    transition: opacity 1s ease 1.25s; }

.carousel {
  position: absolute;
  top: 0; }

.select-box {
  position: absolute;
  z-index: 2;
  left: 90px;
  top: 243.56px;
  width: 160px;
  height: auto; }
  .select-box-invert {
    opacity: 0.5;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    -webkit-transition: -webkit-transform 1.5s ease;
    transition: -webkit-transform 1.5s ease;
    transition: transform 1.5s ease;
    transition: transform 1.5s ease, -webkit-transform 1.5s ease; }
  .select-box.select-box--white {
    z-index: 3;
    -webkit-animation: blink 0.4s forwards;
            animation: blink 0.4s forwards; }

.red-lines__container {
  position: absolute;
  z-index: 1;
  left: 40px;
  top: 165px;
  overflow: hidden;
  width: 0;
  height: 0;
  -webkit-transition: width 0.3s ease-in 0.5s, height 0.5s ease-in 0.5s;
  transition: width 0.3s ease-in 0.5s, height 0.5s ease-in 0.5s; }

.red-lines,
.slider-visible .red-lines__container {
  width: 260px;
  height: 260px; }

.slider-visible.slider .slider-btn svg {
  -webkit-transform: scaleX(1);
          transform: scaleX(1); }

.slider-visible.slider .slider-btn span {
  -webkit-animation: fade-in 0.5s linear 0.7s forwards;
          animation: fade-in 0.5s linear 0.7s forwards; }

.btn--rect {
  position: absolute; }
  .btn--rect-text {
    position: absolute;
    z-index: 1;
    width: 100%;
    padding-right: 10px;
    font-size: 30px;
    line-height: 46px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    font-weight: 700; }

.menu.inactive .menu-item {
  -webkit-animation: fade-out 0.2s ease forwards;
          animation: fade-out 0.2s ease forwards; }
  .menu.inactive .menu-item:nth-child(2) {
    -webkit-animation: fade-out 0.2s ease 0.4s forwards;
            animation: fade-out 0.2s ease 0.4s forwards; }
  .menu.inactive .menu-item:nth-child(3) {
    -webkit-animation: fade-out 0.2s ease 0.2s forwards;
            animation: fade-out 0.2s ease 0.2s forwards; }

.menu .menu-item.active span:first-child {
  -webkit-animation: fade-in-out 0.5s linear 0.2s forwards;
          animation: fade-in-out 0.5s linear 0.2s forwards; }

.menu .menu-item.active::before, .menu .menu-item.active::after {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.menu.selecting .menu-item.active span:first-child {
  -webkit-animation: blink 0.5s linear !important;
          animation: blink 0.5s linear !important; }

.menu-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .menu-item-content {
    position: relative; }
  .menu-item img {
    display: inline-block;
    margin-left: 5px;
    height: 28px;
    min-width: 54px; }
  .menu-item span {
    position: relative;
    z-index: 0;
    font-size: 4.5rem; }
  .menu-item span:first-child {
    position: absolute;
    z-index: 1;
    left: calc(50%);
    top: calc(50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    color: #e71d93;
    opacity: 0; }
  .menu-item::before, .menu-item::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin: 0 5px;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transition: transform 0.3s ease, opacity 0.3s ease, -webkit-transform 0.3s ease; }
  .menu-item::before {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid whitesmoke;
    -webkit-filter: drop-shadow(-3px 0 0 #e71d93);
            filter: drop-shadow(-3px 0 0 #e71d93);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .menu-item::after {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid whitesmoke;
    -webkit-filter: drop-shadow(3px 0 0 #e71d93);
            filter: drop-shadow(3px 0 0 #e71d93);
    -webkit-transform: translateX(50%);
            transform: translateX(50%); }

audio {
  position: absolute; }

.audio-toggle {
  width: 60px;
  height: 60px;
  outline: 0;
  border: 0; }
  .audio-toggle-with-background {
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px); }
  .audio-toggle-wrapper {
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease; }
    .audio-toggle-wrapper.hide {
      opacity: 0; }
    .audio-toggle-wrapper.show {
      opacity: 1; }
  .audio-toggle-background {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1; }

.slider .avatar-carousel .player-box {
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-140%) translateY(-50%);
          transform: translateX(-140%) translateY(-50%); }

.slider .avatar-carousel.inactive .player-box {
  -webkit-animation: fade-out 0.5s ease 0.2s forwards;
          animation: fade-out 0.5s ease 0.2s forwards; }

.slider .avatar-carousel.inactive .avatar-carousel-item.carousel-item--active.carousel-item--prev {
  -webkit-animation: fade-out 0.5s ease 0.4s forwards;
          animation: fade-out 0.5s ease 0.4s forwards; }

.slider .avatar-carousel.inactive .avatar-carousel-item.carousel-item--active.carousel-item--current {
  -webkit-animation: fade-out 0.5s ease 0.6s forwards;
          animation: fade-out 0.5s ease 0.6s forwards; }

.slider .avatar-carousel.inactive .avatar-carousel-item.carousel-item--active.carousel-item--next {
  -webkit-animation: fade-out 0.5s ease 0.2s forwards;
          animation: fade-out 0.5s ease 0.2s forwards; }

.slider .avatar-carousel:not(.avatar-carousel--used) .player-box {
  -webkit-animation: fade-in 0.5s ease 0.5s forwards;
          animation: fade-in 0.5s ease 0.5s forwards; }

.slider .avatar-carousel:not(.avatar-carousel--used) .avatar-carousel-item.carousel-item--active.carousel-item--prev {
  -webkit-animation: fade-in 0.5s ease 0.5s forwards;
          animation: fade-in 0.5s ease 0.5s forwards; }

.slider .avatar-carousel:not(.avatar-carousel--used) .avatar-carousel-item.carousel-item--active.carousel-item--current {
  -webkit-animation: fade-in 0.5s ease 0.8s forwards;
          animation: fade-in 0.5s ease 0.8s forwards; }

.slider .avatar-carousel:not(.avatar-carousel--used) .avatar-carousel-item.carousel-item--active.carousel-item--next {
  -webkit-animation: fade-in 0.5s ease 1.1s forwards;
          animation: fade-in 0.5s ease 1.1s forwards; }

.carousel-item {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  -webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transition: transform 0.5s ease, opacity 0.5s ease, -webkit-transform 0.5s ease;
  opacity: 0; }
  .carousel-item--current {
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }

.avatar-carousel-item {
  height: 138.04px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .avatar-carousel-item.carousel-item--prev {
    -webkit-transform: translateX(-50%) translateY(-250%);
            transform: translateX(-50%) translateY(-250%); }
  .avatar-carousel-item.carousel-item--prev.carousel-item--active {
    -webkit-transform: translateX(-50%) translateY(-150%);
            transform: translateX(-50%) translateY(-150%); }
  .avatar-carousel-item.carousel-item--current {
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }
  .avatar-carousel-item.carousel-item--next.carousel-item--active {
    -webkit-transform: translateX(-50%) translateY(50%);
            transform: translateX(-50%) translateY(50%); }
  .avatar-carousel-item.carousel-item--next {
    -webkit-transform: translateX(-50%) translateY(150%);
            transform: translateX(-50%) translateY(150%); }
  .avatar-carousel-item img {
    position: relative;
    left: 50%;
    top: 50%;
    height: 85px;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }


.game {
  position: relative;
  width: 100%;
  height: 100%;
  height: 812px;
  width: 400px; }
  .game-pad {
    position: relative;
    width: 100%;
    height: 100%; }
  .game-score {
    position: relative;
    display: inline-block;
    padding: 10px;
    margin: 2px;
    background-color: #2b1851;
    font-family: 'RepetitionScrolling';
    font-size: 2rem;
    color: #f12977; }
    .game-score:first-child::before, .game-score:last-child::before {
      content: '';
      position: absolute;
      z-index: 0;
      top: 12.5%;
      display: block;
      width: 20%;
      height: 75%;
      background-color: #2b1851; }
    .game-score:first-child::before {
      left: -12.5%; }
    .game-score:last-child::before {
      right: -12.5%;
      left: auto; }
  .game-scores {
    position: absolute;
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .game-message {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 50px;
    font-size: 2rem;
    text-align: center;
    background-color: rgba(2, 0, 13, 0.781); }
  .game__container {
    position: absolute;
    bottom: 0;
    left: 4290px;
    width: 400px; }
  .game-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 100%; }
    .game-box .building {
      position: absolute;
      padding-left: 20px;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 580px;
      min-height: 100%; }

.block-rage-blocks,
.game__panel {
  position: absolute;
  top: 85px;
  left: 25px;
  width: 352px; }

.block-rage-block-col,
.block-rage-blocks-col,
.game__panel-col {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin: 0 2px; }
  .block-rage-block-col svg,
  .block-rage-blocks-col svg,
  .game__panel-col svg {
    width: 100%;
    height: 100%; }

.block-rage-block-row,
.block-rage-blocks-row,
.game__panel-row {
  margin: 4px 0;
  height: 40px; }

.game__panel-row--unavailable {
  position: relative;
  height: 40px;
  overflow: hidden;
  background: url(/static/media/line-bang.8d3414a4.svg) repeat-x;
  background-size: contain;
  margin: 4px 0; }
  .game__panel-row--unavailable::before {
    content: '';
    height: 40px;
    width: 352px;
    background: url(/static/media/line-bang.8d3414a4.svg) repeat-x;
    background-size: contain;
    background-position-x: -25px; }
  .game__panel-row--unavailable::before {
    position: absolute;
    left: -46px;
    width: 392px; }

.block-rage-blocks {
  z-index: 10; }

.block-rage-block {
  position: absolute; }

.game__panel {
  z-index: 9; }

.block-rage-blocks-col svg {
  max-width: 100%; }

.game__panel-col {
  background-color: #02000d;
  opacity: 0.14; }

.enemy-response {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%); }
  .enemy-response__shadow {
    width: 200px; }
  .enemy-response__user-avatar {
    position: absolute;
    top: 100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .enemy-response__message {
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    padding: 9px 30px 23px 30px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    .enemy-response__message-bcg {
      width: 180px; }

.game-over__message--bottom .enemy-response__message {
  padding: 23px 30px 9px 30px; }

.bang {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .bang__bcg {
    width: 128px; }
  .bang p {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    margin: 0;
    font-size: 2rem;
    color: #ffffff;
    text-transform: uppercase; }

.App {
  position: relative;
  height: 100vh !important;
  background-color: black; }

*,
html,
:root {
  cursor: none; }

* {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent; }

input {
  -webkit-user-select: initial !important;
     -moz-user-select: initial !important;
      -ms-user-select: initial !important;
          user-select: initial !important; }

.main-loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #000000;
  padding: 95px 49px;
  width: 420px;
  height: 812px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  padding: 81.2px 40px; }
  .main-loader p {
    font-size: 2.5rem;
    color: #ffffff;
    margin: 0;
    width: 0;
    overflow: hidden;
    word-wrap: none;
    white-space: nowrap; }
    .main-loader p:nth-child(1) {
      -webkit-animation: type 0.5s steps(20) 0.35s forwards;
              animation: type 0.5s steps(20) 0.35s forwards; }
    .main-loader p:nth-child(2) {
      -webkit-animation: type 0.5s steps(20) 0.7s forwards;
              animation: type 0.5s steps(20) 0.7s forwards; }
    .main-loader p:nth-child(3) {
      -webkit-animation: type 0.5s steps(20) 1.05s forwards;
              animation: type 0.5s steps(20) 1.05s forwards; }
    .main-loader p:nth-child(4) {
      -webkit-animation: type 0.5s steps(20) 1.4s forwards;
              animation: type 0.5s steps(20) 1.4s forwards; }
    .main-loader p:nth-child(5) {
      -webkit-animation: type 0.5s steps(20) 1.75s forwards;
              animation: type 0.5s steps(20) 1.75s forwards; }
    .main-loader p:nth-child(6) {
      -webkit-animation: type 0.5s steps(20) 2.1s forwards;
              animation: type 0.5s steps(20) 2.1s forwards; }
    .main-loader p:nth-child(7) {
      -webkit-animation: type 0.5s steps(20) 2.45s forwards;
              animation: type 0.5s steps(20) 2.45s forwards; }
    .main-loader p:nth-child(8) {
      -webkit-animation: type 0.5s steps(20) 2.8s forwards;
              animation: type 0.5s steps(20) 2.8s forwards; }
    .main-loader p:nth-child(9) {
      -webkit-animation: type 0.5s steps(20) 3.15s forwards;
              animation: type 0.5s steps(20) 3.15s forwards; }
    .main-loader p:nth-child(10) {
      -webkit-animation: type 0.5s steps(20) 3.5s forwards;
              animation: type 0.5s steps(20) 3.5s forwards; }
    .main-loader p:nth-child(11) {
      -webkit-animation: type 0.5s steps(20) 3.85s forwards;
              animation: type 0.5s steps(20) 3.85s forwards; }
    .main-loader p:nth-child(12) {
      -webkit-animation: type 0.5s steps(20) 4.2s forwards;
              animation: type 0.5s steps(20) 4.2s forwards; }
    .main-loader p:nth-child(13) {
      -webkit-animation: type 0.5s steps(20) 4.55s forwards;
              animation: type 0.5s steps(20) 4.55s forwards; }
    .main-loader p:nth-child(14) {
      -webkit-animation: type 0.5s steps(20) 4.9s forwards;
              animation: type 0.5s steps(20) 4.9s forwards; }
    .main-loader p:nth-child(15) {
      -webkit-animation: type 0.5s steps(20) 5.25s forwards;
              animation: type 0.5s steps(20) 5.25s forwards; }
    .main-loader p:nth-child(16) {
      -webkit-animation: type 0.5s steps(20) 5.6s forwards;
              animation: type 0.5s steps(20) 5.6s forwards; }
    .main-loader p:nth-child(17) {
      -webkit-animation: type 0.5s steps(20) 5.95s forwards;
              animation: type 0.5s steps(20) 5.95s forwards; }
    .main-loader p:nth-child(18) {
      -webkit-animation: type 0.5s steps(20) 6.3s forwards;
              animation: type 0.5s steps(20) 6.3s forwards; }
    .main-loader p:nth-child(19) {
      -webkit-animation: type 0.5s steps(20) 6.65s forwards;
              animation: type 0.5s steps(20) 6.65s forwards; }
    .main-loader p:nth-child(20) {
      -webkit-animation: type 0.5s steps(20) 7s forwards;
              animation: type 0.5s steps(20) 7s forwards; }
    .main-loader p:nth-child(21) {
      -webkit-animation: type 0.5s steps(20) 7.35s forwards;
              animation: type 0.5s steps(20) 7.35s forwards; }
    .main-loader p:nth-child(22) {
      -webkit-animation: type 0.5s steps(20) 7.7s forwards;
              animation: type 0.5s steps(20) 7.7s forwards; }
  .main-loader .white-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: #fff;
    opacity: 0.15;
    -webkit-animation: opacity-fade-in 2.5s linear;
            animation: opacity-fade-in 2.5s linear; }
  .main-loader .belt-1,
  .main-loader .belt-2 {
    position: absolute;
    left: 0;
    bottom: -10%;
    width: 100%; }
  .main-loader .belt-1 {
    -webkit-animation: belt-translate-y-fade-out 2.5s linear 200ms;
            animation: belt-translate-y-fade-out 2.5s linear 200ms; }
  .main-loader .belt-2 {
    -webkit-animation: belt-translate-y 2.5s linear 1s;
            animation: belt-translate-y 2.5s linear 1s; }
  .main-loader .belt-element:nth-child(1) {
    height: 2px;
    opacity: 0.1;
    background-color: #fff; }
  .main-loader .belt-element:nth-child(2) {
    height: 2px; }
  .main-loader .belt-element:nth-child(3) {
    height: 25px;
    opacity: 0.1;
    background-color: #fff; }
  .main-loader .belt-element:nth-child(4) {
    height: 5px; }
  .main-loader .belt-element:nth-child(5) {
    height: 5px;
    opacity: 0.1;
    background-color: #fff; }

@-webkit-keyframes type {
  from {
    width: 0; }
  to {
    width: 100%; } }

@keyframes type {
  from {
    width: 0; }
  to {
    width: 100%; } }

@-webkit-keyframes opacity-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.15; } }

@keyframes opacity-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.15; } }

@-webkit-keyframes belt-translate-y-fade-out {
  100% {
    -webkit-transform: translateY(-105vh);
            transform: translateY(-105vh);
    opacity: 0; } }

@keyframes belt-translate-y-fade-out {
  100% {
    -webkit-transform: translateY(-105vh);
            transform: translateY(-105vh);
    opacity: 0; } }

@-webkit-keyframes belt-translate-y {
  100% {
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh); } }

@keyframes belt-translate-y {
  100% {
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh); } }

.message {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 406px;
  width: 100%; }
  .message .dash-array {
    display: inline-block;
    width: 120px; }
  .message__heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px; }
  .message__background {
    height: 326px;
    width: 100%;
    padding: 40px 0; }
    .message__background, .message__background::after, .message__background::before {
      content: '';
      background-color: white;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      display: block; }
    .message__background::before {
      right: 0;
      height: 406px;
      width: 70%; }
    .message__background::after {
      right: 0;
      width: 70%;
      height: 366px;
      padding-left: 20px; }
  .message__title {
    display: inline-block;
    color: black;
    text-transform: uppercase;
    font-weight: bold; }
  .message__text {
    margin: 0;
    color: black;
    font-size: 2.5rem;
    text-align: left; }
  .message__content {
    position: relative;
    z-index: 10;
    padding: 10px 45px;
    text-align: left; }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-light-in {
  from {
    opacity: 0.8; }
  to {
    opacity: 1; } }

@keyframes fade-light-in {
  from {
    opacity: 0.8; }
  to {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fade-in-out {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-in-out {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fade-inactive-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-inactive-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes btnTransform {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(0.5vh);
            transform: translateY(0.5vh); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes btnTransform {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(0.5vh);
            transform: translateY(0.5vh); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes moveLeft {
  0% {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%); }
  80% {
    -webkit-transform: translateX(300%);
            transform: translateX(300%); }
  100% {
    -webkit-transform: translateX(300%);
            transform: translateX(300%); } }

@keyframes moveLeft {
  0% {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%); }
  80% {
    -webkit-transform: translateX(300%);
            transform: translateX(300%); }
  100% {
    -webkit-transform: translateX(300%);
            transform: translateX(300%); } }

@-webkit-keyframes moveLeftPrevent {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  20% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  40% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  60% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  80% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  100% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); } }

@keyframes moveLeftPrevent {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  20% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  40% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  60% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  80% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); }
  100% {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%); } }

@-webkit-keyframes pulse {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  20% {
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  60% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  80% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); } }

@keyframes pulse {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  20% {
    opacity: 0.9;
    -webkit-transform: scale(1);
            transform: scale(1); }
  40% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  60% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  80% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); } }

@-webkit-keyframes blink {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  50% {
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes blink {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  50% {
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes show-box-shadow {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(7px, 7px);
            transform: translate(7px, 7px); } }

@keyframes show-box-shadow {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(7px, 7px);
            transform: translate(7px, 7px); } }

@-webkit-keyframes fade-in-from-bottom {
  0% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-from-bottom {
  0% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fade-in-from-top {
  0% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-from-top {
  0% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes fade-out-from-top {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out-from-top {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fade-out-from-bottom {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out-from-bottom {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeAndScaleSparks {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2); } }

@keyframes fadeAndScaleSparks {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2); } }

.pad-name {
  position: absolute;
  left: 50%;
  top: 130px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .pad-name__text {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    color: #fff; }

.pad-carousel .pad-loader, .pad-carousel .pad-name, .pad-carousel .player-box {
  opacity: 0; }

.pad-loaders {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-65%) translateY(100%);
          transform: translateX(-65%) translateY(100%); }

.pad-loader {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .pad-loader:first-child {
    opacity: 0;
    margin-bottom: 15px; }
  .pad-loader:last-child {
    opacity: 0; }
  .pad-loader__label {
    margin-right: 10px; }
  .pad-loader .loader--yellow {
    -webkit-transition: width 0.4s linear;
    transition: width 0.4s linear; }
  .pad-loader .loader__dots {
    -webkit-transition: -webkit-transform 0.4s linear;
    transition: -webkit-transform 0.4s linear;
    transition: transform 0.4s linear;
    transition: transform 0.4s linear, -webkit-transform 0.4s linear; }

.pad-carousel:not(.pad-carousel--used) .pad-name {
  -webkit-animation: fade-in 1s ease 1s forwards;
          animation: fade-in 1s ease 1s forwards; }

.pad-carousel:not(.pad-carousel--used) .player-box {
  -webkit-animation: fade-in 0.5s ease 1.2s forwards;
          animation: fade-in 0.5s ease 1.2s forwards; }

.pad-carousel:not(.pad-carousel--used) .pad-loader:first-child {
  -webkit-animation: fade-in 1s ease 1.4s forwards;
          animation: fade-in 1s ease 1.4s forwards; }

.pad-carousel:not(.pad-carousel--used) .pad-loader:last-child {
  -webkit-animation: fade-in 1s ease 1.6s forwards;
          animation: fade-in 1s ease 1.6s forwards; }

.pad-carousel:not(.pad-carousel--used) .pad-carousel-item.carousel-item--current {
  -webkit-animation: fade-in 0.5s ease 1.2s forwards;
          animation: fade-in 0.5s ease 1.2s forwards; }

.pad-carousel.inactive .pad-name {
  -webkit-animation: fade-out 0.5s ease 0.2s forwards;
          animation: fade-out 0.5s ease 0.2s forwards; }

.pad-carousel.inactive .player-box {
  -webkit-animation: fade-out 0.5s ease 0.4s forwards;
          animation: fade-out 0.5s ease 0.4s forwards; }

.pad-carousel.inactive .pad-loader:first-child {
  -webkit-animation: fade-out 0.5s ease 0.6s forwards;
          animation: fade-out 0.5s ease 0.6s forwards; }

.pad-carousel.inactive .pad-loader:last-child {
  -webkit-animation: fade-out 0.5s ease 0.8s forwards;
          animation: fade-out 0.5s ease 0.8s forwards; }

.pad-carousel.inactive .pad-carousel-item.carousel-item--current {
  -webkit-animation: fade-out 0.5s ease 1s forwards;
          animation: fade-out 0.5s ease 1s forwards; }

.pad-carousel-item.carousel-item {
  -webkit-transform: translateX(-100%) translateY(-50%);
          transform: translateX(-100%) translateY(-50%); }

.pad-carousel-item.carousel-item--current {
  -webkit-transform: translateX(-100%) translateY(-50%);
          transform: translateX(-100%) translateY(-50%); }

.pad-carousel-item.carousel-item--prev {
  -webkit-transform: translateX(-100%) translateY(-80%);
          transform: translateX(-100%) translateY(-80%); }

.pad-carousel-item.carousel-item--next {
  -webkit-transform: translateX(-100%) translateY(30%);
          transform: translateX(-100%) translateY(30%); }

.sign-in {
  position: absolute;
  z-index: 15;
  bottom: 0;
  left: 0;
  padding: 81.2px 40px;
  background-color: #000000;
  width: 400px;
  min-height: 852px;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left; }
  .sign-in p, .sign-in span {
    font-size: 3.5rem; }
  .sign-in__input-wrapper {
    display: flex;
    align-items: center; }
    .sign-in__input-wrapper span {
      text-transform: uppercase;
      line-height: 30px; }
  .sign-in__section {
    margin-bottom: 40px; }
  .sign-in form label {
    position: relative; }
  .sign-in__logo,
  .sign-in form {
    width: 280px;
    margin: 0 auto; }
  .sign-in__label, .sign-in__nickname {
    margin: 0;
    color: white;
    text-transform: uppercase;
    font-family: 'VT323', monospace; }
  .sign-in__nickname {
    position: absolute;
    opacity: 0; }
  .sign-in__logo {
    position: relative;
    max-width: 100%;
    margin: 0 auto 5vh auto; }
    .sign-in__logo .dash-array {
      left: 50%;
      display: block;
      width: 100%; }
    .sign-in__logo-text {
      width: 100%;
      margin: 10px 0; }
  .sign-in .moving-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2b2b2b;
    -webkit-animation: slid-out 7s linear 4s both;
            animation: slid-out 7s linear 4s both; }
  .sign-in .white-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: #fff;
    opacity: 0.15; }

@-webkit-keyframes slid-out {
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); } }

@keyframes slid-out {
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); } }

.sky-background {
  position: absolute;
  z-index: -1;
  background-color: #190736; }
  .sky-background,
  .sky-background img,
  .sky-background svg {
    width: 100%;
    height: 100%; }

.shooting-star {
  position: absolute;
  z-index: 0;
  width: 2px;
  height: 2px;
  background-color: #84bdff; }
  .shooting-star::before {
    position: absolute;
    bottom: 100%;
    left: 0.5px;
    width: 1px;
    height: 15px;
    content: '';
    background-color: #84bdff;
    -webkit-transform-origin: bottom;
            transform-origin: bottom; }
  .shooting-star--from-left {
    -webkit-animation: shootingStar2 2s linear;
            animation: shootingStar2 2s linear; }
    .shooting-star--from-left::before {
      -webkit-transform: rotate(-30deg);
              transform: rotate(-30deg); }
  .shooting-star--from-right {
    -webkit-animation: shootingStar1 2s linear;
            animation: shootingStar1 2s linear; }
    .shooting-star--from-right::before {
      -webkit-transform: rotate(30deg);
              transform: rotate(30deg); }

@-webkit-keyframes shootingStar1 {
  from {
    -webkit-transform: translateY(10vh) translateX(0);
            transform: translateY(10vh) translateX(0); }
  to {
    -webkit-transform: translateY(101vh) translateX(-400px);
            transform: translateY(101vh) translateX(-400px); } }

@keyframes shootingStar1 {
  from {
    -webkit-transform: translateY(10vh) translateX(0);
            transform: translateY(10vh) translateX(0); }
  to {
    -webkit-transform: translateY(101vh) translateX(-400px);
            transform: translateY(101vh) translateX(-400px); } }

@-webkit-keyframes shootingStar2 {
  from {
    -webkit-transform: translateY(10vh) translateX(0);
            transform: translateY(10vh) translateX(0); }
  to {
    -webkit-transform: translateY(101vh) translateX(400px);
            transform: translateY(101vh) translateX(400px); } }

@keyframes shootingStar2 {
  from {
    -webkit-transform: translateY(10vh) translateX(0);
            transform: translateY(10vh) translateX(0); }
  to {
    -webkit-transform: translateY(101vh) translateX(400px);
            transform: translateY(101vh) translateX(400px); } }

.spinner {
  position: absolute;
  height: 54px;
  width: 54px;
  -webkit-transform-origin: center;
          transform-origin: center; }
  .spinner__dot {
    position: absolute;
    width: 10px;
    height: 10px; }
    .spinner__dot:last-child {
      margin-top: 44px; }
    .spinner__dot-group {
      position: absolute;
      top: 0;
      left: 22px;
      width: 10px;
      height: 54px;
      -webkit-transform-origin: center;
              transform-origin: center; }
  .spinner .spinner__dot-group:nth-child(1) {
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
    -webkit-animation: endlessRotate1 0.6s steps(8) infinite forwards;
            animation: endlessRotate1 0.6s steps(8) infinite forwards; }
  .spinner .spinner__dot-group:nth-child(2) {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
    -webkit-animation: endlessRotate2 0.6s steps(8) infinite forwards;
            animation: endlessRotate2 0.6s steps(8) infinite forwards; }
  .spinner .spinner__dot-group:nth-child(3) {
    -webkit-transform: rotateZ(135deg);
            transform: rotateZ(135deg);
    -webkit-animation: endlessRotate3 0.6s steps(8) infinite forwards;
            animation: endlessRotate3 0.6s steps(8) infinite forwards; }
  .spinner .spinner__dot-group:nth-child(4) {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
    -webkit-animation: endlessRotate4 0.6s steps(8) infinite forwards;
            animation: endlessRotate4 0.6s steps(8) infinite forwards; }

@-webkit-keyframes endlessRotate1 {
  0% {
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg); }
  100% {
    -webkit-transform: rotateZ(405deg);
            transform: rotateZ(405deg); } }

@keyframes endlessRotate1 {
  0% {
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg); }
  100% {
    -webkit-transform: rotateZ(405deg);
            transform: rotateZ(405deg); } }

@-webkit-keyframes endlessRotate2 {
  0% {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }
  100% {
    -webkit-transform: rotateZ(450deg);
            transform: rotateZ(450deg); } }

@keyframes endlessRotate2 {
  0% {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }
  100% {
    -webkit-transform: rotateZ(450deg);
            transform: rotateZ(450deg); } }

@-webkit-keyframes endlessRotate3 {
  0% {
    -webkit-transform: rotateZ(135deg);
            transform: rotateZ(135deg); }
  100% {
    -webkit-transform: rotateZ(495deg);
            transform: rotateZ(495deg); } }

@keyframes endlessRotate3 {
  0% {
    -webkit-transform: rotateZ(135deg);
            transform: rotateZ(135deg); }
  100% {
    -webkit-transform: rotateZ(495deg);
            transform: rotateZ(495deg); } }

@-webkit-keyframes endlessRotate4 {
  0% {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg); }
  100% {
    -webkit-transform: rotateZ(540deg);
            transform: rotateZ(540deg); } }

@keyframes endlessRotate4 {
  0% {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg); }
  100% {
    -webkit-transform: rotateZ(540deg);
            transform: rotateZ(540deg); } }

.user-matching {
  position: relative;
  height: 812px;
  width: 400px;
  margin: 0 auto; }
  .user-matching__message {
    position: absolute; }
    .user-matching__message--top {
      top: -50px; }
    .user-matching__message--bottom {
      bottom: -65px; }
      .user-matching__message--bottom .enemy-response__message {
        padding: 23px 30px 9px 30px; }
      .user-matching__message--bottom img {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
  .user-matching__container {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .user-matching__section {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33.33%;
    width: 200px; }
    .user-matching__section:nth-child(2) {
      top: 33.33%; }
    .user-matching__section:nth-child(3) {
      top: 66.66%; }
  .user-matching__user-name--top {
    bottom: 182.5px; }
  .user-matching__user-name {
    position: absolute;
    z-index: 0;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    white-space: nowrap;
    overflow: hidden;
    width: 120px; }
    .user-matching__user-name span {
      max-width: 80%; }
    .user-matching__user-name.enemy {
      -webkit-transform: translateY(-50%) translateX(0);
              transform: translateY(-50%) translateX(0); }
  .user-matching__user-avatar {
    position: absolute;
    height: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(10%);
            transform: translateY(-50%) translateX(10%);
    height: 77px;
    width: 60px;
    z-index: 1; }
    .user-matching__user-avatar.enemy {
      -webkit-transform: translateY(-50%) translateX(-80%);
              transform: translateY(-50%) translateX(-80%); }
  .user-matching__user--top {
    top: 0; }
  .user-matching__user--bottom {
    bottom: 0; }
  .user-matching__main-content {
    position: absolute;
    top: 0; }
  .user-matching__main-content, .user-matching__lines {
    margin-left: 40px;
    height: 365px;
    width: 320px; }

.not-found {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  top: 50%;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
  .not-found .message__content {
    margin: 85px 0; }
  .not-found__back-button {
    position: absolute;
    bottom: 40px;
    display: flex;
    align-items: center;
    color: white;
    background-color: transparent;
    border: 0;
    outline: 0;
    text-transform: uppercase; }
    .not-found__back-button::before {
      content: '';
      display: block;
      width: 60px;
      height: 20px;
      background-image: url(/static/media/baack.926ed3c7.svg);
      background-repeat: no-repeat;
      background-size: contain; }
  .not-found .btn--rect {
    position: relative; }

.qr-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%); }

.qr-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 265px;
  margin-bottom: 30px; }

.qr-title {
  text-transform: uppercase;
  font-size: 40px;
  color: #fff; }

.qr-container {
  padding: 15px;
  background-color: #fff; }

.qr-comment {
  font-size: 30px;
  color: #fff; }

.desktop-only-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background-color: black; }
  .desktop-only-screen .message {
    position: absolute;
    max-width: 500px;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }
    .desktop-only-screen .message__background {
      display: none; }
    .desktop-only-screen .message__heading span, .desktop-only-screen .message__content p {
      color: white; }

