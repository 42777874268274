$retro-font-family: "VT323", monospace;
$screenHeight: 812px;
$screenWidth: 400px;
$parallax-width: 1.5 * $screenWidth;
$parallaxInitialDuration: 4s;
$fastParallaxDuration: 1s;
$ufoWidth: 25vw;
$cowOffsetBottom: 10vh;
$cowUfoContainerHeight: 420px;
$cowWidth: 90;
$cowHeight: 65;
$carouWidth: 340px;
$sliderContainerHeight: 0.76 * $screenHeight;
$sliderSelectBoxWidth: 40vw;
$sliderSelectBoxHeight: 25vh;
$sliderRedLinesHeight: 260px;

$parallaxOffset: 150px;

$arrowBtnWidth: 0.5 * $screenWidth;
$arrowWidth: 35%;

$parallaxDurations: 4.5s, 3.5s, 2.5s, 1.5s;
$objectsDurations: 0.25s, 0.75s, 1, 1,25s, 1.5s, 1.75s;
$avatarsDurations: 0.75s, 1s, 1.25s;

$av-sel-slide-in--duration: 2.8s;
$select-box--closer--duration: 800ms;
$red-lines--spread--duration: 300ms;
$box--blink--duration: 200ms;
$box--blink--iter-count: 3;
$box--blinking--duration: $box--blink--duration * $box--blink--iter-count;

// UFO Cow Abduction Scene----------------------------------------------------
$ufo-fly-in--duration: 8s;
$ufo-fly-in--delay: 4s;
$ufo__beam-2--delay: $ufo-fly-in--delay + 2s;
$cow--abduction-delay: $ufo__beam-2--delay + 1s;
$cow--abduction-duration: 2s;
$ufo__beam-2--duration: $cow--abduction-duration + 700ms;


// Game box ------------------------------------------------------------------
$gameBoxWidth: 85.33vw;
$gameBoxHeight: 34.6vw;
