body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

polyline#Path-25 {
  cursor: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 38px;
  height: 48px;
}

::-webkit-scrollbar-track {
  background-color: #CCC3DF;
}

::-webkit-scrollbar-thumb {
  background-color: #472888;
}


::-webkit-scrollbar-button {
  display: block;
  width: 40px;
  height: 60px;
  background-color: #311D5C;

  &:vertical:decrement {
    height: 70px;
    background-color: #311d5c;
    background-image: url("./assets/img/mainView/scrollTop.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
  }
  
  &:vertical:increment {
    height: 90px;
    background-color: #311d5c;
    background-image: url("./assets/img/mainView/scroll.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:vertical:increment:start { display: none; }
  &:vertical:decrement:end { display: none; }
}