@import '../../style/utilities/variables';
@import '../../style/utilities/mixins';

.sky-background {
  position: absolute;
  z-index: -1;
  background-color: #190736;

  &,
  img,
  svg {
    width: 100%;
    height: 100%;
  }
}

.shooting-star {
  position: absolute;
  z-index: 0;
  width: 2px;
  height: 2px;
  background-color: #84bdff;

  &::before {
    position: absolute;
    bottom: 100%;
    left: 0.5px;
    width: 1px;
    height: 15px;
    content: '';
    background-color: #84bdff;
    transform-origin: bottom;
  }
  
  &--from-left {
    animation: shootingStar2 2s linear;

    &::before {
      transform: rotate(-30deg);
    }
  }
  
  &--from-right {
    animation: shootingStar1 2s linear;

    &::before {
      transform: rotate(30deg);
    }
  }
}

@keyframes shootingStar1 {
  from {
    transform: translateY(10vh) translateX(0);
  }
  to {
    transform: translateY(101vh) translateX(-$screenWidth);
  }
}

@keyframes shootingStar2 {
  from {
    transform: translateY(10vh) translateX(0);
  }
  to {
    transform: translateY(101vh) translateX($screenWidth);
  }
}