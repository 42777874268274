@import '../../style/utilities/variables';
@import '../../style/utilities/mixins';

.spinner {
  position: absolute;
  height: 54px;
  width: 54px;
  transform-origin: center;

  &__dot {
    position: absolute;
    width: 10px;
    height: 10px;

    &:last-child {
      margin-top: 44px;
    }

    &-group {
      position: absolute;
      top: 0;
      left: 22px;
      width: 10px;
      height: 54px;
      transform-origin: center;
    }
  }

  @include getSpinnerDots(4, '.spinner__dot-group');
}

@include getSpinnerGroupsAnimation(4);
