@import url("https://fonts.googleapis.com/css?family=VT323");
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-light-in {
  from {
    opacity: 0.8; }
  to {
    opacity: 1; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-in-out {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-inactive-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes btnTransform {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(0.5vh); }
  100% {
    transform: translateY(0); } }

@keyframes moveLeft {
  0% {
    transform: translateX(-150%); }
  80% {
    transform: translateX(300%); }
  100% {
    transform: translateX(300%); } }

@keyframes moveLeftPrevent {
  0% {
    transform: translateX(150%); }
  20% {
    transform: translateX(-300%); }
  40% {
    transform: translateX(-300%); }
  60% {
    transform: translateX(-300%); }
  80% {
    transform: translateX(-300%); }
  100% {
    transform: translateX(-300%); } }

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.2); }
  20% {
    opacity: 0.9;
    transform: scale(1); }
  40% {
    opacity: 0;
    transform: scale(0.2); }
  60% {
    opacity: 0;
    transform: scale(0.2); }
  80% {
    opacity: 0;
    transform: scale(0.2); }
  100% {
    opacity: 0;
    transform: scale(0.2); } }

@keyframes blink {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  50% {
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes show-box-shadow {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(7px, 7px); } }

@keyframes fade-in-from-bottom {
  0% {
    transform: translateY(-25px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-from-top {
  0% {
    transform: translateY(25px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-out-from-top {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out-from-bottom {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeAndScaleSparks {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(2); } }

* {
  box-sizing: border-box;
  font-family: "VT323", monospace; }

@font-face {
  font-family: 'RepetitionScrolling';
  font-weight: normal;
  src: url("./assets/fonts/RepetitionScrolling.ttf"); }

:root {
  font-size: 10px; }

* {
  font-size: 3rem; }

html, body {
  user-zoom: fixed; }

body {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #ffffff;
  touch-action: manipulation; }
  body, body #root {
    overflow: hidden;
    width: 100vw;
    height: 100vh; }

.container {
  background-color: #190736; }

html {
  font-size: 62.5%;
  overflow: hidden; }

.hide-overflow {
  overflow: hidden; }

.GameContainer {
  position: relative;
  z-index: 1;
  top: 12%;
  height: 70%;
  max-width: 640px;
  margin: 0 auto;
  overflow: hidden;
  transform-origin: center;
  background-color: #000;
  color: white; }

.hide {
  animation: fade-out 0.5s ease forwards; }

button {
  background: transparent;
  outline: 0; }

.text--error {
  color: red;
  font-size: 16px; }

.text--pre {
  white-space: pre; }

.text--stretch {
  text-align: justify; }

.btn {
  background: transparent;
  border: 0;
  outline: 0; }
  .btn.clicked {
    animation: btn-up-down 0.2s ease; }
  .btn svg {
    max-width: 100%; }
  .btn--transform {
    animation: btnTransform 0.3s ease; }

.carousel-slider {
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 20px;
  transition: "transform 400ms ease"; }

.d-none {
  display: none; }

@keyframes btn-up-down {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(3px); }
  100% {
    transform: translateY(0); } }

div.logo {
  left: 120px; }

.logo {
  width: 100%; }

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 365.4px;
  left: 100px;
  width: 200px; }

.menu button {
  text-transform: uppercase;
  color: #f0f0f0;
  background: none;
  border: none;
  padding: 0.5rem;
  margin: 10px 0; }

.ground {
  position: absolute;
  bottom: 0;
  width: 4800px;
  left: -10%;
  height: 108px;
  background-image: url("./assets/svg/podloga2.svg");
  background-repeat: repeat-x; }

.shrub0-img {
  position: absolute;
  bottom: 108px;
  height: 107px;
  transform: translateX(-60%); }

.shrub1 {
  position: absolute;
  bottom: 0;
  height: 140px; }
  .shrub1.parallax.parallax-2 {
    z-index: 1; }
  .shrub1 img {
    transform: translateX(30%); }

.shrub2a {
  position: absolute;
  bottom: -15px;
  left: 2025px;
  height: 170px; }

.shrub4 {
  position: absolute;
  bottom: 108px;
  left: 600px;
  height: 107px; }

.ceiling1 {
  position: absolute;
  z-index: 3;
  top: 0;
  height: 112px;
  transform: translateX(-25%); }

.lamppost {
  position: absolute;
  bottom: 0;
  left: 1200px;
  width: 154px !important; }

.shrub5 {
  position: absolute;
  bottom: -5px;
  left: 1440px; }

.players-matching {
  position: absolute;
  top: 20vh;
  left: 500vw; }

.shrub6 {
  position: absolute;
  bottom: -2vh;
  left: 2800px; }

.shrub6.shrub7 {
  left: 5238px; }
  .shrub6.shrub7,
  .shrub6.shrub7 .big-shurb {
    bottom: auto;
    top: 0; }
  .shrub6.shrub7 .big-shurb {
    transform: none; }

.big-shurb {
  position: absolute;
  height: 812px;
  bottom: 0;
  transform: rotateY(-180deg) rotateZ(-90deg); }

.ceiling2 {
  position: absolute;
  left: 2400px;
  height: 40vh; }

.blackout {
  background-color: black;
  position: absolute;
  top: 0;
  left: 3600px;
  width: 400px !important;
  height: 100%; }
  .blackout-2 {
    left: 4980px;
    width: 100% !important; }
    .blackout-2 .user-matching {
      margin: 0 85px; }

.lamppost2 {
  position: absolute;
  bottom: 0;
  left: 2320px;
  width: 60px; }

.black4 {
  position: absolute;
  left: 3840px;
  width: 1400px !important;
  height: 50%; }
  .black4--top {
    top: 100px; }
    .black4--top::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
      transform: translateY(-100%);
      background: black; }
  .black4--bottom {
    left: 3880px;
    bottom: -20px;
    width: 1500px !important;
    height: 60%; }
    .black4--bottom img {
      min-width: 100%;
      height: 100%; }

.game-pad-parallax {
  position: absolute;
  bottom: 0;
  left: 4270px;
  z-index: 100; }

.text-rect {
  width: 140px;
  height: 43px; }
  .text-rect svg {
    width: 100%;
    z-index: 0; }
  .text-rect span {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    display: block;
    padding: 0 9px 0 0;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    text-align: center;
    line-height: 43px; }

.game-over__scores {
  position: absolute;
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
  font-size: 5rem; }
  .game-over__scores span {
    font-family: "RepetitionScrolling";
    color: #f12977;
    background-image: url("./assets/svg/score-bcg.svg");
    background-size: 6.33333px 6.6px; }

.game-over__message--bottom .enemy-response__message.enemy-response__message {
  margin-top: 12%;
  padding: 0; }

.game-over .user-matching__user-avatar {
  transform: translateY(-50%); }

.game-over__message {
  top: -25%;
  left: 50%;
  transform: translateX(-50%);
  text-transform: capitalize;
  opacity: 0;
  animation: fade-in 0.5s ease 5s forwards; }
  .game-over__message--bottom {
    top: auto;
    bottom: -25%;
    margin-top: 5%; }
    .game-over__message--bottom img {
      transform: rotate(180deg); }

.game-over .enemy-response__message {
  padding: 0;
  width: 100%;
  margin-top: 5%; }

.game-over__msg {
  position: absolute;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 4.5rem;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%); }
  .game-over__msg--top {
    position: absolute;
    top: 0;
    transform: translateY(-200%) translateX(-50%); }
  .game-over__msg--bottom {
    position: absolute;
    top: auto;
    bottom: 0;
    transform: translateY(300%) translateX(-50%); }
  .game-over__msg--bottom, .game-over__msg--top {
    font-size: 3rem; }
  .game-over__msg--clock {
    height: 40px; }

.game-over__button {
  position: absolute;
  z-index: 100;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%); }
  .game-over__button .btn {
    position: relative; }

.game-over__message {
  position: absolute; }
  .game-over__message p {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%) translateY(-50%); }

.game-over .game-over__scores .game-score {
  padding: 5px; }
  .game-over .game-over__scores .game-score, .game-over .game-over__scores .game-score::before {
    background-color: transparent; }

.game-over .game-over__scores .game-scores {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  transform: none; }

.game-over .user-matching__user--top .game-over__scores {
  left: 50%;
  padding-right: 35px;
  transform: translateX(-100%) translateY(-50%); }

.game-over .user-matching__user--bottom .game-over__scores {
  left: 50%;
  padding-left: 35px;
  transform: translateY(-50%); }

.game-over .game-over .user-matching__user--bottom span {
  color: #7257b4; }

.arrow-back {
  position: fixed;
  z-index: 100;
  top: 30px;
  left: 22px;
  width: 35px;
  height: 25px;
  box-sizing: content-box;
  border: 0;
  outline: 0;
  opacity: 0;
  animation: fadeIn 0.5s ease 4s 1 forwards;
  background-image: url("./assets/svg/baack.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.2s ease; }
  .arrow-back:hover {
    transform: scale(1.25); }
  .arrow-back img {
    width: 100%; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.parallax.parallax-0 {
  z-index: 0; }

.parallax.parallax-1 {
  z-index: 1; }

.parallax.parallax-2 {
  z-index: 2; }

.parallax.parallax-3 {
  z-index: 3; }

.parallax-full {
  position: absolute;
  height: 100%;
  width: 6462px; }

.carousel {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  padding: 105px 0; }
  .carousel-container {
    position: relative;
    height: 100%;
    width: 100%; }

.slider .carousel.carousel--used .player-box, .slider .carousel.carousel--used .carousel-item--active, .slider .carousel.carousel--used .pad-name, .slider .carousel.carousel--used .pad-loader {
  opacity: 1; }

.slider {
  z-index: 5;
  margin: 36px 0 0 45px; }
  .slider .building {
    height: 675px; }
  .slider-building {
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 1315px; }
    .slider-building.parallax.parallax-1 {
      z-index: 2; }
  .slider__controls {
    position: absolute;
    z-index: 10;
    top: 0;
    width: 100%;
    height: 100%; }
  .slider__content {
    position: relative;
    z-index: 3;
    height: 617.12px;
    width: 340px;
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .slider-visible .select-box-invert {
    transform: translate(7px, 7px); }
  .slider .player-box {
    position: absolute;
    top: 50%;
    z-index: 3;
    height: auto;
    width: 130px;
    transform: translateY(-50%) translateX(150%); }
  .slider .slider-btn {
    position: absolute;
    z-index: 10;
    bottom: 50px;
    left: 70px;
    width: 200px;
    height: 46px;
    text-align: center;
    transition: transform 0.2s ease; }
    .slider .slider-btn:hover {
      transform: scale(1.2); }
    .slider .slider-btn span {
      opacity: 0; }
    .slider .slider-btn .btn--rect {
      position: relative; }
    .slider .slider-btn svg {
      transform: scaleX(0);
      transition: transform 0.7s ease-in; }
  .slider .btn-arrow {
    z-index: 10;
    text-align: center;
    height: 60px;
    width: 200px;
    border: none;
    opacity: 1;
    transition: transform 0.2s ease opacity 1s ease; }
    .slider .btn-arrow__wrapper {
      position: absolute;
      z-index: 2;
      top: 0;
      display: flex;
      left: 50%;
      align-items: center;
      height: 100px;
      transform: translateX(-50%) translateY(-100%); }
      .slider .btn-arrow__wrapper--bottom {
        top: auto;
        bottom: 0;
        transform: translateX(-50%) translateY(100%); }
    .slider .btn-arrow::before {
      content: '';
      display: inline-block;
      height: 60px;
      width: 35%;
      text-align: center;
      background: url("./assets/svg/arrow-up.svg") no-repeat;
      background-size: 100% 90%;
      background-position: center; }
    .slider .btn-arrow:hover {
      transform: scale(1.2); }
    .slider .btn-arrow:disabled::before {
      opacity: 0.5; }
    .slider .btn-arrow--up {
      top: 25px; }
    .slider .btn-arrow--down {
      bottom: 25px; }
      .slider .btn-arrow--down::before {
        background-image: url("./assets/svg/down-arrow.svg"); }
  .slider .btn--arrow-down {
    transform: rotate(180deg); }
  .slider .carousel__slot--visible {
    transition: opacity 1s ease 0.75s; }
  .slider .carousel__slot--visible + .carousel__slot--visible {
    transition: opacity 1s ease 1s; }
  .slider .carousel__slot--visible + .carousel__slot--visible + .carousel__slot--visible {
    transition: opacity 1s ease 1.25s; }

.carousel {
  position: absolute;
  top: 0; }

.select-box {
  position: absolute;
  z-index: 2;
  left: 90px;
  top: 243.56px;
  width: 160px;
  height: auto; }
  .select-box-invert {
    opacity: 0.5;
    transform: translate(0, 0);
    transition: transform 1.5s ease; }
  .select-box.select-box--white {
    z-index: 3;
    animation: blink 0.4s forwards; }

.red-lines__container {
  position: absolute;
  z-index: 1;
  left: 40px;
  top: 165px;
  overflow: hidden;
  width: 0;
  height: 0;
  transition: width 0.3s ease-in 0.5s, height 0.5s ease-in 0.5s; }

.red-lines,
.slider-visible .red-lines__container {
  width: 260px;
  height: 260px; }

.slider-visible.slider .slider-btn svg {
  transform: scaleX(1); }

.slider-visible.slider .slider-btn span {
  animation: fade-in 0.5s linear 0.7s forwards; }

.btn--rect {
  position: absolute; }
  .btn--rect-text {
    position: absolute;
    z-index: 1;
    width: 100%;
    padding-right: 10px;
    font-size: 30px;
    line-height: 46px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    font-weight: 700; }
