.desktop-only-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background-color: black;

  .message {
    position: absolute;
    max-width: 500px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    &__background {
      display: none;
    }

    &__heading span, &__content p {
      color: white;
    }
  }
}