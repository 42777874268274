@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-light-in {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-inactive-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes btnTransform {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.5vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(-150%);
  }
  80% {
    transform: translateX(300%);
  }
  100% {
    transform: translateX(300%);
  }
}

@keyframes moveLeftPrevent {
  0% {
    transform: translateX(150%);
  }
  20% {
    transform: translateX(-300%);
  }
  40% {
    transform: translateX(-300%);
  }
  60% {
    transform: translateX(-300%);
  }
  80% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  20% {
    opacity: 0.9;
    transform: scale(1);
  }
  40% {
    opacity: 0;
    transform: scale(0.2);
  }
  60% {
    opacity: 0;
    transform: scale(0.2);
  }
  80% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 0;
    transform: scale(0.2);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes show-box-shadow {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(7px, 7px);
  }
}

@keyframes fade-in-from-bottom {
  0% {
    transform: translateY(-25px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-from-top {
  0% {
    transform: translateY(25px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-out-from-top {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-out-from-bottom {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeAndScaleSparks {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}