@import '../../style/utilities/variables';
@import '../../style/utilities/mixins';

.main-loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #000000;
  padding: 95px 49px;
  width: $screenWidth * 1.05;
  height: $screenHeight;
  transform-origin: top left;
  padding: 0.1 * $screenHeight 0.1 * $screenWidth;

  p {
    font-size: 2.5rem;
    color: #ffffff;
    margin: 0;
    width: 0;
    overflow: hidden;
    word-wrap: none;
    white-space: nowrap;

    @include type();
  }

  .white-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: #fff;
    opacity: 0.15;
    animation: opacity-fade-in 2.5s linear;
  }

  .belt-1,
  .belt-2 {
    position: absolute;
    left: 0;
    bottom: -10%;
    width: 100%;
  }

  .belt-1 {
    animation: belt-translate-y-fade-out 2.5s linear 200ms;
  }

  .belt-2 {
    animation: belt-translate-y 2.5s linear 1s;
  }

  .belt-element:nth-child(1) {
    height: 2px;
    opacity: 0.1;
    background-color: #fff;
  }
  .belt-element:nth-child(2) {
    height: 2px;
  }
  .belt-element:nth-child(3) {
    height: 25px;
    opacity: 0.1;
    background-color: #fff;
  }
  .belt-element:nth-child(4) {
    height: 5px;
  }
  .belt-element:nth-child(5) {
    height: 5px;
    opacity: 0.1;
    background-color: #fff;
  }
}

@keyframes type {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes opacity-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.15;
  }
}

@keyframes belt-translate-y-fade-out {
  100% {
    transform: translateY(-105vh);
    opacity: 0;
  }
}

@keyframes belt-translate-y {
  100% {
    transform: translateY(-100vh);
  }
}
