@mixin arrow {
  text-align: center;
  height: 60px;
  width: $arrowBtnWidth;
  border: none;
  opacity: 1;

  &__wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    display: flex;
    left: 50%;
    align-items: center;
    height: 100px;
    transform: translateX(-50%) translateY(-100%);

    &--bottom {
      top: auto;
      bottom: 0;
      transform: translateX(-50%) translateY(100%);
    }
  }

  &::before {
    content: '';
    display: inline-block;
    height: 60px;
    width: $arrowWidth;
    text-align: center;
    background: url("./assets/svg/arrow-up.svg") no-repeat;
    background-size: 100% 90%;
    background-position: center;
  }
}

@mixin getSpinnerDots($dotGroupsAmount, $className) {
  @for $dotGroup from 1 to $dotGroupsAmount + 1 {

    #{$className}:nth-child(#{$dotGroup}) {
      transform: rotateZ(45deg * $dotGroup);
      animation: endlessRotate#{$dotGroup} 0.6s steps(8) infinite forwards;
    }
  }
}

@mixin getSpinnerGroupsAnimation($dotGroupsAmount) {
  @for $dotGroup from 1 to $dotGroupsAmount + 1 {
    @keyframes endlessRotate#{$dotGroup} {
      0% {
          transform: rotateZ(45deg * $dotGroup);
      }
      100% {
          transform: rotateZ(45deg * $dotGroup + 360deg);
      }
    }
  }
}

@mixin avatar {
  opacity: 0;
  animation-name: avatars--fade-in;
  animation-fill-mode: forwards;
  animation-duration: 300ms;
}

@mixin avParallax($name, $from, $to) {
  @keyframes #{$name} {
    from {
      transform: translateX($from);
    }
    to {
      transform: translateX($to);
    }
  }
}

@mixin getParallaxes {
  @for $i from 0 through length($parallaxDurations) - 1 {
    .parallax.parallax-#{$i} {
      z-index: $i;
    }
  }
}

@mixin showSlides($name) {
  #{$name} {
    transition: opacity 1s ease nth($avatarsDurations, 1);
  }

  #{$name}+#{$name} {
    transition: opacity 1s ease nth($avatarsDurations, 2);
  }

  #{$name}+#{$name}+#{$name} {
    transition: opacity 1s ease nth($avatarsDurations, 3);
  }
}

@mixin type {
  @for $i from 1 through 22 {
    &:nth-child(#{$i}) {
      animation: type 0.5s steps(20) #{$i * 0.35}s forwards;
    }
  }
}

@mixin  logoMask() {
  $steps: 20;
  $width: 50%;
  $maxX: 100%;
  $top: 0%;
  $stepHeight: 100% / $steps;
  $stepWidth: $width / $steps;
  $vertices: ();
  $cX: $width;
  $cY: $top;
  $vertices: 0 $cY;

  @while $cX < $maxX {
    @for $i from 0 to 2 {
      $cY: $cY + $i * $stepHeight;
      $vertices: $vertices, $cX $cY;
    }

    $cX: $cX + $stepWidth;
  }

  $cX: $cX - $width;

  @while $cX > 0 {
    @for $i from 0 to 2 {
      $cY: $cY - $i * $stepHeight;
      $vertices: $vertices, $cX $cY;
    }

    $cX: $cX - $stepWidth;
  }

  clip-path: polygon($vertices);
}

@function countWidth() {
  @return 6462px;
}
