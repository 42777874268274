@import '../../style/utilities/variables';

audio {
  position: absolute;
}

.audio-toggle {
  width: 60px;
  height: 60px;
  outline: 0;
  border: 0;

  &-with-background {
    transform: translate(-10px, -10px);
  }

  &-wrapper {
    transition: opacity 0.2s ease;

    &.hide {
      opacity: 0;
    }

    &.show {
      opacity: 1;
    }
  }

  &-background {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}