@import '../../style/utilities/variables';

.game-pad {
  position: relative;
  height: 100%;
  width: 100%;
  cursor: default;

  &__images,
  &__buttons {
    left: 50%;
    top: 50%;
    width: 100vmax;
    transform: translateX(-50%) translateY(-50%);
  }

  &__images {
    position: relative;
    display: flex;
    align-items: stretch;
    overflow: hidden;
  }

  &__buttons {
    position: absolute;
  }
}

.padBgc {
  z-index: 0;
  width: 100vmax;
}

.padBgc,
.middle {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.middle {
  z-index: 2;
}

.middle,
.padBgc,
.right-side,
.left-side {
  position: absolute;
  height: 100%;
  transform-origin: center;
}

.left-side {
  z-index: 1;
  top: 0;
  left: 0;
}

.right-side {
  z-index: 1;
  bottom: 0;
  right: 0;
}

.pad-arrow-back.arrow-back {
  top: 0;
  left: 0;
  width: 30vmin;
  height: 20vmin;
  padding: 0;
  background-image: url('../../assets/svg/back-arrwo.svg');
  animation: fadeIn 0.5s ease $parallaxInitialDuration forwards;
  background-size: contain;
  background-position: top left;

  &:hover {
    transform: none;
  }
}

@media screen and (orientation: portrait) {
  .game-pad {
    &__buttons,
    &__images {
      left: 50%;
      top: 0;
      transform-origin: top left;
      transform: rotate(90deg) translateY(-50%);
    }
  }
}