.slider .avatar-carousel .player-box {
  left: 50%;
  opacity: 0;
  transform: translateX(-140%) translateY(-50%); }

.slider .avatar-carousel.inactive .player-box {
  animation: fade-out 0.5s ease 0.2s forwards; }

.slider .avatar-carousel.inactive .avatar-carousel-item.carousel-item--active.carousel-item--prev {
  animation: fade-out 0.5s ease 0.4s forwards; }

.slider .avatar-carousel.inactive .avatar-carousel-item.carousel-item--active.carousel-item--current {
  animation: fade-out 0.5s ease 0.6s forwards; }

.slider .avatar-carousel.inactive .avatar-carousel-item.carousel-item--active.carousel-item--next {
  animation: fade-out 0.5s ease 0.2s forwards; }

.slider .avatar-carousel:not(.avatar-carousel--used) .player-box {
  animation: fade-in 0.5s ease 0.5s forwards; }

.slider .avatar-carousel:not(.avatar-carousel--used) .avatar-carousel-item.carousel-item--active.carousel-item--prev {
  animation: fade-in 0.5s ease 0.5s forwards; }

.slider .avatar-carousel:not(.avatar-carousel--used) .avatar-carousel-item.carousel-item--active.carousel-item--current {
  animation: fade-in 0.5s ease 0.8s forwards; }

.slider .avatar-carousel:not(.avatar-carousel--used) .avatar-carousel-item.carousel-item--active.carousel-item--next {
  animation: fade-in 0.5s ease 1.1s forwards; }

.carousel-item {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0; }
  .carousel-item--current {
    transform: translateX(-50%) translateY(-50%); }

.avatar-carousel-item {
  height: 138.04px;
  user-select: none; }
  .avatar-carousel-item.carousel-item--prev {
    transform: translateX(-50%) translateY(-250%); }
  .avatar-carousel-item.carousel-item--prev.carousel-item--active {
    transform: translateX(-50%) translateY(-150%); }
  .avatar-carousel-item.carousel-item--current {
    transform: translateX(-50%) translateY(-50%); }
  .avatar-carousel-item.carousel-item--next.carousel-item--active {
    transform: translateX(-50%) translateY(50%); }
  .avatar-carousel-item.carousel-item--next {
    transform: translateX(-50%) translateY(150%); }
  .avatar-carousel-item img {
    position: relative;
    left: 50%;
    top: 50%;
    height: 85px;
    transform: translateX(-50%) translateY(-50%); }
