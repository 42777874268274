@import '../../style/utilities/variables';
@import '../../style/utilities/mixins';

$boxWidth: 0.9 * $screenWidth;
$panelPadding: 10px;
$panelColAmount: 8;
$panelWidth: $screenWidth - 48px;
$panelCellColor: #02000d;

.game {
  position: relative;
  width: 100%;
  height: 100%;
  height: $screenHeight;
  width: $screenWidth;

  &-pad {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &-score {
    position: relative;
    display: inline-block;
    padding: 10px;
    margin: 2px;
    background-color: #2b1851;
    font-family: 'RepetitionScrolling';
    font-size: 2rem;
    color: #f12977;

    &:first-child,
    &:last-child {
      &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 12.5%;
        display: block;
        width: 20%;
        height: 75%;
        background-color: #2b1851;
      }
    }

    &:first-child::before {
      left: -12.5%;
    }

    &:last-child::before {
      right: -12.5%;
      left: auto;
    }
  }

  &-scores {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &-message {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    font-size: 2rem;
    text-align: center;
    background-color: rgba(2, 0, 13, 0.781);
  }

  &__container {
    position: absolute;
    bottom: 0;
    left: 4290px;
    width: $screenWidth;
  }

  &-box {
    position: absolute;
    top: 0;
    left: 0;
    width: $screenWidth;
    height: 100%;

    .building {
      position: absolute;
      padding-left: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: $screenWidth + 180px;
      min-height: 100%;
    }
  }
}

.block-rage-blocks,
.game__panel {
  position: absolute;
  top: 85px;
  left: 25px;
  width: $panelWidth;
}

.block-rage-block,
.block-rage-blocks,
.game__panel {
  &-col {
    display: inline-block;
    height: $panelWidth / 8 - 4px;
    width: $panelWidth / 8 - 4px;
    margin: 0 2px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &-row {
    margin: 4px 0;
    height: $panelWidth / 8 - 4px;
  }
}

.game__panel-row--unavailable {
  position: relative;
  height: $panelWidth / 8 - 4px;
  overflow: hidden;
  background: url('../../assets/svg/line-bang.svg') repeat-x;
  background-size: contain;
  margin: 4px 0;

  &::before {
    content: '';
    height: $panelWidth / 8 - 4px;
    width: $panelWidth;
    background: url('../../assets/svg/line-bang.svg') repeat-x;
    background-size: contain;
    background-position-x: -25px;
  }

  &::before {
    position: absolute;
    left: -46px;
    width: $panelWidth + 40px;
  }
}

.block-rage-blocks {
  z-index: 10;
}

.block-rage-block {
  position: absolute;
}

.game__panel {
  z-index: 9;
}

.block-rage-blocks-col svg {
  max-width: 100%;
}

.game__panel-col {
  background-color: $panelCellColor;
  opacity: 0.14;
}

.enemy-response {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  &__shadow {
    width: $screenWidth / 2;
  }

  &__user-avatar {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__message {
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    padding: 9px 30px 23px 30px;
    transform: translateX(-50%);
    text-align: center;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &-bcg {
      width: 0.45 * $screenWidth;
    }
  }
}

.game-over__message--bottom {
  .enemy-response {
    &__message {
      padding: 23px 30px 9px 30px;
    }
  }
}

.bang {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  &__bcg {
    width: 0.32 * $screenWidth;
  }

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0;
    font-size: 2rem;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.App {
  position: relative;
  height: 100vh !important;
  background-color: black;
}

*,
html,
:root {
  cursor: none;
}

* {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

input {
  user-select: initial !important;
}