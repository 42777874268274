@import '../../style/utilities/variables';
@import '../../style/utilities/mixins';

$linesHeight: 365px;

.user-matching {
  position: relative;
  height: $screenHeight;
  width: $screenWidth;
  margin: 0 auto;

  &__message {
    position: absolute;

    &--top {
      top: -50px;
    }

    &--bottom {
      bottom: -65px;

      .enemy-response__message {
        padding: 23px 30px 9px 30px;
      }

      img {
        transform: rotate(180deg);
      }
    }
  }

  &__container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__section {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33.33%;
    width: 200px;

    &:nth-child(2) {
      top: 33.33%;
    }

    &:nth-child(3) {
      top: 66.66%;
    }
  }

  &__user-name--top {
    bottom: $linesHeight / 2;
  }

  &__user {
    &-name {
      position: absolute;
      z-index: 0;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      white-space: nowrap;
      overflow: hidden;
      width: 0.3 * $screenWidth;

      span {
        max-width: 80%;
      }

      &.enemy {
        transform: translateY(-50%) translateX(0);
      }
    }

    &-avatar {
      position: absolute;
      height: 100%;
      top: 50%;
      transform: translateY(-50%) translateX(10%);
      height: 77px;
      width: 60px;
      z-index: 1;

      &.enemy {
        transform: translateY(-50%) translateX(-80%);
      }
    }

    &--top {
      & {
        top: 0;
      }
    }

    &--bottom {
      & {
        bottom: 0;
      }
    }
  }

  &__main-content {
    position: absolute;
    top: 0;
  }

  &__main-content,
  &__lines {
    margin-left: 0.1 * $screenWidth;
    height: $linesHeight;
    width: 0.8 * $screenWidth;
  }
}

.not-found {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  top: 50%;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: $screenWidth;
  transform: translateY(-50%);

  .message__content {
    margin: 85px 0;
  }

  &__back-button {
    position: absolute;
    bottom: 40px;
    display: flex;
    align-items: center;
    color: white;
    background-color: transparent;
    border: 0;
    outline: 0;
    text-transform: uppercase;
  
    &::before {
      content: '';
      display: block;
      width: 60px;
      height: 20px;
      background-image: url('../../assets/svg/baack.svg');
      background-repeat: no-repeat;
      background-size: contain;

    }
  }

  .btn--rect {
    position: relative;
  }
}

.qr-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.qr-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 265px;
  margin-bottom: 30px;
}

.qr-title {
  text-transform: uppercase;
  font-size: 40px;
  color: #fff;
}

.qr-container {
  padding: 15px;
  background-color: #fff;
}

.qr-comment {
  font-size: 30px;
  color: #fff;
}