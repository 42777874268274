@import '../../style/utilities/variables';
@import '../../style/utilities/mixins';
@import '../../style/utilities/animations';

.pad-name {
  position: absolute;
  left: 50%;
  top: 130px;
  transform: translateX(-50%);

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translateX(-50%) translateY(-50%);
    color: #fff;
  }
}
.pad-carousel {
  .pad-loader, .pad-name, .player-box {
    opacity: 0;
  }
}

.pad-loaders {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-65%) translateY(100%);

}

.pad-loader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:first-child {
    opacity: 0;
    margin-bottom: 15px;
  }

  &:last-child {
    opacity: 0;
  }

  &__label {
    margin-right: 10px;
  }

  .loader {
    &--yellow {
      transition: width 0.4s linear;
    }

    &__dots {
      transition: transform 0.4s linear;
    }
  }
}

.pad-carousel {
  &:not(.pad-carousel--used) {
    .pad-name {
      animation: fade-in 1s ease 1s forwards; //ui loader
    }

    .player-box {
      animation: fade-in 0.5s ease 1.2s forwards;
    }

    .pad-loader {
      &:first-child {
        animation: fade-in 1s ease 1.4s forwards; //ux loader
      }
    
      &:last-child {
        animation: fade-in 1s ease 1.6s forwards; //ui loader
      }
    }

    .pad-carousel-item.carousel-item--current {
      animation: fade-in 0.5s ease 1.2s forwards;
    }
  }

  &.inactive {
    .pad-name {
      animation: fade-out 0.5s ease 0.2s forwards;
    }

    .player-box  {
      animation: fade-out 0.5s ease 0.4s forwards;
    }

    .pad-loader {
      &:first-child {
        animation: fade-out 0.5s ease 0.6s forwards;
      }
    
      &:last-child {
        animation: fade-out 0.5s ease 0.8s forwards;
      }
    }

    .pad-carousel-item.carousel-item--current {
      animation: fade-out 0.5s ease 1s forwards;
    }
  }
}

.pad-carousel-item {
  &.carousel-item {
    transform: translateX(-100%) translateY(-50%);
  }

  &.carousel-item--current {
    transform: translateX(-100%) translateY(-50%);
  }

  &.carousel-item--prev {
    transform: translateX(-100%) translateY(-80%);
  }

  &.carousel-item--next {
    transform: translateX(-100%) translateY(30%);
  }
}