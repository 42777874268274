@import '../../style/utilities/variables';

.message {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: $screenHeight / 2;
  width: 100%;

  .dash-array {
    display: inline-block;
    width: 120px;
  }

  &__heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px;
  }

  &__background {
    height: $screenHeight / 2 - 80px;
    width: 100%;
    padding: 40px 0;

    &,
    &::after,
    &::before {
      content: '';
      background-color: white;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
    }

    &::before {
      right: 0;
      height: $screenHeight / 2;
      width: 70%;
    }

    &::after {
      right: 0;
      width: 70%;
      height: $screenHeight / 2 - 40px;
      padding-left: 20px;
    }
  }

  &__title {
    display: inline-block;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__text {
    margin: 0;
    color: black;
    font-size: 2.5rem;
    text-align: left;
  }

  &__content {
    position: relative;
    z-index: 10;
    padding: 10px 45px;
    text-align: left;
  }
}
